import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../components/SiteMetadata'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content/Content'
import PageBanner from '../components/PageBanner/PageBanner'

import Img from 'gatsby-image'

import heroBackground from '../img/necrovictology-cover.jpg'

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="section">
            <PageContent className="content" content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { title } = useSiteMetadata()
  const { markdownRemark: post } = data
  return (
    <Layout>
      <Helmet>
        <title>{`${post.frontmatter.title} | ${title}`}</title>
      </Helmet>
      <PageBanner title={post.frontmatter.title} />

      <div className="container pb-176">
        <figure className="block md:mb-56">
          <Img fluid={post.frontmatter.bandPhoto.image.childImageSharp.fluid} alt="Repulsive Vision" />
          <figcaption className="text-12 lg:text-14 flex flex-col sm:flex-row items-start sm:space-x-24 opacity-50 mt-8">
            {post.frontmatter.bandPhoto.imageCaption.map((item) => {
              return (
              <>
              <div className="">
                <span className="font-bold">{ item.name }</span> 
                <span className=""> - </span>
                <span>{ item.role }</span>
                </div>
              {/* <span className="">
                <svg width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2.5" r="2" fill="#444444" />
                </svg>
              </span> */}
              </>
              )
            })}
          </figcaption>
        </figure>
        <section className="block pt-48">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6">
              <HTMLContent className="content" content={post.html} />
            </div>
            <div className="col-span-12 mt-24 lg:mt-0 lg:col-start-8 lg:col-span-5">
              <img src={ heroBackground } alt="Necrovictology"/>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        bandPhoto {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageCaption {
            role
            name
          }
        }
      }
    }
  }
`
